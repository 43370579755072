
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { AlertModule } from '@/modules/alert/store'
import { CommentModule } from '@/modules/comment/store'
import { DiagramModule } from '@/modules/diagram/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'

@Component({
  components: {
    Dialog
  },
  name: 'AccuracyHelpDialog'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  commentModule = getModule(CommentModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion?.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionsId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get ojectInvalidLinksCount () {
    return Object
      .values(this.modelModule.objects)
      .map(o => Object.values(o.links))
      .flat()
      .filter(o => 'status' in o && o.status === 'invalid')
      .length
  }

  get activeCommentsInaccurateCount () {
    return Object
      .values(this.commentModule.activeComments)
      .filter(o => o.body.type === 'inaccurate' && o.body.status === 'open')
      .length
  }

  opened () {
    analytics.accuracyHelpDialog.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }
}
