
import { Domain } from '@icepanel/platform-api-client'
import debounce from 'lodash/debounce'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { RecycleScroller } from 'vue-virtual-scroller'
import { getModule } from 'vuex-module-decorators'

import ContextMenuItem from '@/components/context-menu/item.vue'
import Menu from '@/components/menu.vue'
import DomainDeleteDialog from '@/modules/domain/components/delete-dialog.vue'
import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { SocketModule } from '@/modules/socket/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'

@Component({
  components: {
    ContextMenuItem,
    DomainDeleteDialog,
    Menu,
    OrganizationUpgradeMenu,
    RecycleScroller
  },
  name: 'DomainDropdown'
})
export default class extends Vue {
  domainModule = getModule(DomainModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  socketModule = getModule(SocketModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)

  @Prop({ type: Boolean }) readonly expanded?: boolean

  @Ref() readonly menuRef!: any
  @Ref() readonly optionsMenuRef!: Menu
  @Ref() readonly searchRef?: HTMLElement

  search = ''
  searchModel = ''
  setSearchDebounce = debounce(this.setSearch.bind(this), 300)

  editingDomainId = ''
  newDomainName = ''

  MAX_VISIBLE_LINES = 9
  DOMAIN_ITEM_LINE_HEIGHT = 34

  focused = false

  get scrollHeight () {
    return Math.min((this.domains.length * this.DOMAIN_ITEM_LINE_HEIGHT), this.MAX_VISIBLE_LINES * this.DOMAIN_ITEM_LINE_HEIGHT) + 8
  }

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get currentDomain () {
    return Object.values(this.domainModule.domains).find(o => o.handleId === this.currentDomainHandleId)
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion?.landscapeId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get domains () {
    return Object.values(this.domainModule.domains).filter(d => d.landscapeId === this.currentLandscapeId)
  }

  get socketError () {
    return this.socketModule.socketSubscriptionActive ? undefined : (this.socketModule.socketSubscriptionError || this.socketModule.socketError)
  }

  get actionsEnabled () {
    return !this.currentShareLink && this.currentVersion?.tags.includes('latest')
  }

  get domainsLoading () {
    return (
      !this.currentShareLink &&
      (!this.landscapeModule.landscapesListStatus.success ||
      !this.organizationModule.organizationsListStatus.success ||
      (this.landscapeModule.landscapeSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.landscapeModule.landscapeSubscriptionStatus.loadingInfo.reconnect) ||
      (this.domainModule.domainsSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.domainModule.domainsSubscriptionStatus.loadingInfo.reconnect))
    )
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get organizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get currentOrganizationPermission () {
    return this.organizationModule.organizationPermission(this.currentOrganization)
  }

  get authorizedToEdit () {
    return this.currentOrganizationPermission === 'admin' || this.currentOrganizationPermission === 'write'
  }

  get domainsLimitReached () {
    return this.organizationLimits.domains <= this.domains.length
  }

  get menuItems () {
    return [
      {
        action: (domain: Domain) => this.renameDomain(domain),
        icon: '$custom-duotone-pen-to-square',
        id: 'rename',
        name: 'Rename domain'
      },
      {
        action: (domain: Domain) => this.deleteDomain(domain),
        disabled: this.domains.length <= 1,
        icon: '$custom-duotone-trash-can',
        id: 'delete',
        name: 'Delete domain',
        type: 'danger'
      }
    ]
  }

  setSearch (term: string) {
    this.search = term
  }

  selectDomain (domain?: Domain) {
    this.$router.push({
      query: {
        domain: domain?.handleId
      }
    })
    this.close()
  }

  async createDomain () {
    const currentLandscape = this.currentLandscape
    const currentVersion = this.currentVersion
    if (!currentLandscape || !currentVersion) {
      return
    }

    const { domain, domainUpsert } = this.domainModule.generateDomain(currentLandscape.id, currentVersion.id, {
      name: 'Domain' + (this.domains.length > 0 ? ` ${this.domains.length + 1}` : '')
    })
    this.domainModule.setDomainVersion(domain)
    this.editorModule.addToTaskQueue({
      func: () => this.domainModule.domainUpsert({
        domainId: domain.id,
        landscapeId: currentLandscape.id,
        props: domainUpsert,
        versionId: currentVersion.id
      })
    })

    await this.$pushQuery({
      domain: domain.handleId
    })

    analytics.domainCreate.track(this, {
      landscapeId: [currentLandscape.id],
      organizationId: [currentLandscape.organizationId]
    })
  }

  renameDomain (domain: Domain) {
    this.newDomainName = domain.name
    this.editingDomainId = domain.id
  }

  updateDomainName (id: string) {
    const currentLandscape = this.currentLandscape
    const currentVersion = this.currentVersion
    if (!currentLandscape || !currentVersion) {
      return
    }

    const { domain, domainUpdate } = this.domainModule.generateDomainCommit(id, {
      name: this.newDomainName || 'Domain'
    })
    this.domainModule.setDomainVersion(domain)
    this.editorModule.addToTaskQueue({
      func: () => this.domainModule.domainUpdate({
        domainId: id,
        landscapeId: currentLandscape.id,
        props: domainUpdate,
        versionId: currentVersion.id
      })
    })
    this.editingDomainId = ''
  }

  deleteDomain (domain: Domain) {
    this.close()
    this.$pushQuery({ domain_delete_dialog: domain.id })
  }

  open () {
    this.menuRef.isActive = true
  }

  close () {
    this.menuRef.isActive = false
  }

  goToDomainDocs () {
    window.open('https://docs.icepanel.io/features/domains', '_blank')
  }
}
