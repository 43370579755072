import * as analytics from '@icepanel/app-analytics'

import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { organizationId } from '@/modules/organization/helpers/analytics-group'

/**
 * Dialogs
 */

export const domainDeleteDialog = new analytics.Event({
  displayName: 'Domain delete dialog',
  name: 'domain_delete_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  organizationId
})

/**
 * Events
 */

export const domainCreate = new analytics.Event({
  displayName: 'Domain create',
  name: 'domain_create'
}, {
  landscapeId,
  organizationId
})
export const domainDelete = new analytics.Event({
  displayName: 'Domain delete',
  name: 'domain_delete'
}, {
  landscapeId,
  organizationId
})
