
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { CommentModule } from '../../store'
import Thread from '../thread.vue'

@Component({
  components: {
    Dialog,
    Thread
  },
  name: 'CommentDialog'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  commentModule = getModule(CommentModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly dialogRef!: Dialog

  loading = true

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentCommentId () {
    return this.$queryValue('comment_thread_dialog')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentComment () {
    return this.currentCommentId ? this.commentModule.activeComments[this.currentCommentId] || this.commentModule.comments[this.currentCommentId] : undefined
  }

  get currentCommentDiagramId () {
    return Object.keys(this.currentComment?.diagrams || {})?.[0]
  }

  get currentCommentDiagram () {
    return this.currentCommentDiagramId ? this.diagramModule.diagrams[this.currentCommentDiagramId] : undefined
  }

  get resolved () {
    return this.currentComment?.body.status === 'resolved' || this.currentComment?.body.status === 'dismissed'
  }

  get title () {
    if (this.resolved) {
      return `Resolved comment - ${this.currentCommentDiagram?.name || 'Comment location deleted'}`
    } else {
      return this.currentCommentDiagram?.name || 'Comment location deleted'
    }
  }

  async load () {
    try {
      this.loading = true

      await this.commentModule.commentsList({
        landscapeId: this.currentLandscapeId,
        status: ['open', 'active', 'resolved', 'dismissed'],
        versionId: this.currentVersionId
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.loading = false
    }
  }
}
