
import { ModelConnectionDirection, ModelObject, ModelObjectType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'
import { DomainModule } from '../store'

interface IAction {
  modelObject?: {
    diagramCount: number
    diagramNames: string[]
    flowCount: number
    flowNames: string[]
    name: string
    type: ModelObjectType
  }
  modelConnection?: {
    diagramCount: number
    diagramNames: string[]
    direction: ModelConnectionDirection
    flowCount: number
    flowNames: string[]
    name: string
    origin: ModelObject
    target: ModelObject
  }
  flow?: {
    name: string
  }
  diagram?: {
    name: string
  }
}

@Component({
  components: {
    Dialog
  },
  name: 'DomainDeleteDialog'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() actionScrollRef!: HTMLElement

  loading = false

  actionCache: IAction[] | null = null

  actionScrollYVisible = false

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get domainDeleteDialog () {
    return this.$queryValue('domain_delete_dialog')
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get domain () {
    return this.domainDeleteDialog ? this.domainModule.domains[this.domainDeleteDialog] : undefined
  }

  get actions () {
    if (this.actionCache) {
      return this.actionCache
    } else {
      const modelObjects = Object.values(this.modelModule.objects).filter(o => o.domainId === this.domain?.id)
      const modelObjectIds = modelObjects.map(o => o.id)
      const modelConnections = Object.values(this.modelModule.connections).filter(o => modelObjectIds.includes(o.originId) || modelObjectIds.includes(o.targetId))
      const childDiagramIds = modelObjects.map(o => o.childDiagramIds).flat()
      const childDiagrams = childDiagramIds.map(o => this.diagramModule.diagrams[o]).filter(o => o)
      const childDiagramFlows = Object.values(this.flowModule.flows).filter(o => childDiagramIds.includes(o.diagramId))

      return [
        ...modelObjects
          .filter(o => o.type !== 'root')
          .map((o): IAction => ({
            modelObject: {
              diagramCount: Object.keys(o.diagrams).length,
              diagramNames: Object.keys(o.diagrams).map(o => this.diagramModule.diagrams[o]?.name).filter(o => o),
              flowCount: Object.keys(o.flows).length,
              flowNames: Object.keys(o.flows).map(o => this.flowModule.flows[o]?.name).filter(o => o),
              name: o.name || `${o.type.slice(0, 1).toUpperCase()}${o.type.slice(1)}`,
              type: o.type
            }
          })),
        ...modelConnections.map((o): IAction => ({
          modelConnection: {
            diagramCount: Object.keys(o.diagrams).length,
            diagramNames: Object.keys(o.diagrams).map(o => this.diagramModule.diagrams[o]?.name).filter(o => o),
            direction: o.direction,
            flowCount: Object.keys(o.flows).length,
            flowNames: Object.keys(o.flows).map(o => this.flowModule.flows[o]?.name).filter(o => o),
            name: o.name || 'Connection',
            origin: this.modelModule.objects[o.originId],
            target: this.modelModule.objects[o.targetId]
          }
        })),
        ...childDiagrams.map((o): IAction => ({
          diagram: {
            name: o.name
          }
        })),
        ...childDiagramFlows.map((o): IAction => ({
          flow: {
            name: o.name
          }
        }))
      ]
    }
  }

  get directionIcons () {
    return (direction: ModelConnectionDirection) => {
      switch (direction) {
        case 'outgoing': return '$fas-long-arrow-alt-right'
        case 'bidirectional': return '$fas-arrows-alt-h'
        default: return '$custom-solid-horizontal-rule'
      }
    }
  }

  @Watch('actions')
  async onActionsChanged () {
    await this.$nextTick()
    this.actionScrollYVisible = this.actionScrollRef ? this.actionScrollRef.scrollHeight > this.actionScrollRef.clientHeight : false
  }

  open () {
    this.actionCache = this.actions
  }

  opened () {
    analytics.domainDeleteDialog.track(this, {
      landscapeId: [this.currentLandscapeId],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  closed () {
    this.actionCache = null
  }

  async deleteDomain () {
    const domain = this.domain
    if (!domain) {
      return
    }

    try {
      this.loading = true

      await this.$replaceQuery({
        domain: undefined
      })

      await this.domainModule.domainDelete({
        domainId: domain.id,
        landscapeId: this.currentLandscape.id,
        versionId: this.currentVersion.id
      })

      await Promise.all(
        Object
          .values(this.domainModule.domains)
          .sort((a, b) => {
            if (a.index === b.index) {
              return a.handleId < b.handleId ? 1 : -1
            } else {
              return a.index > b.index ? 1 : -1
            }
          })
          .map(async (o, index) => {
            if (this.domainModule.domains[o.id].index !== index) {
              const { domain, domainUpdate } = this.domainModule.generateDomainCommit(o.id, {
                index
              })
              this.domainModule.setDomainVersion(domain)

              await this.domainModule.domainUpdate({
                domainId: o.id,
                landscapeId: this.currentLandscape.id,
                props: domainUpdate,
                versionId: this.currentVersion.id
              })
            }
          }))

      analytics.domainDelete.track(this, {
        landscapeId: [this.currentLandscapeId],
        organizationId: [this.currentLandscape.organizationId]
      })

      this.editorModule.resetTaskLists()

      await this.$replaceQuery({
        domain: undefined,
        domain_delete_dialog: undefined
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
      throw err
    } finally {
      this.loading = false
    }
  }
}
